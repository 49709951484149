(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/query-selectors/assets/javascripts/api/select-get-player-wagers.js') >= 0) return;  svs.modules.push('/components/tipsen/query-selectors/assets/javascripts/api/select-get-player-wagers.js');
"use strict";


const {
  tipsenApi
} = svs.components.tipsen.store;

const selectReducePaginationKeys = (state, _ref) => {
  var _queryData$data;
  let {
    count,
    status,
    productId,
    pagingSortKey,
    accObj
  } = _ref;
  if (!accObj) {
    accObj = {
      currentKeys: [pagingSortKey],
      nextKey: null
    };
  }
  const queryData = tipsenApi.endpoints.getPlayerWagers.select({
    count,
    status,
    productId,
    pagingSortKey
  })(state);
  if (queryData !== null && queryData !== void 0 && (_queryData$data = queryData.data) !== null && _queryData$data !== void 0 && _queryData$data.wagers) {
    const wagers = queryData.data.wagers;
    if (queryData.data.moreItems) {
      const lastWagersPaginationKey = wagers[wagers.length - 1].pagingSortKey;
      if (accObj.nextKey) {
        accObj.currentKeys.push(accObj.nextKey);
      }
      if (accObj.nextKey !== lastWagersPaginationKey) {
        accObj.nextKey = lastWagersPaginationKey;
      }
      return selectReducePaginationKeys(state, {
        count,
        status,
        productId,
        pagingSortKey: lastWagersPaginationKey,
        accObj
      });
    } else if (accObj.nextKey && !queryData.data.moreItems) {
      accObj.currentKeys.push(accObj.nextKey);
      accObj.nextKey = null;
    }
  }
  return accObj;
};

const selectReduceWagerCount = (state, _ref2) => {
  var _queryData$data2;
  let {
    count,
    status,
    productId,
    pagingSortKey,
    wagerCount = 0
  } = _ref2;
  const queryData = tipsenApi.endpoints.getPlayerWagers.select({
    count,
    status,
    productId,
    pagingSortKey
  })(state);
  if (queryData !== null && queryData !== void 0 && (_queryData$data2 = queryData.data) !== null && _queryData$data2 !== void 0 && _queryData$data2.wagers) {
    const wagers = queryData.data.wagers;
    wagerCount += wagers.length;
    if (queryData.data.moreItems) {
      const lastWagersPaginationKey = wagers[wagers.length - 1].pagingSortKey;
      return selectReduceWagerCount(state, {
        count,
        status,
        productId,
        pagingSortKey: lastWagersPaginationKey,
        wagerCount
      });
    }
  }
  return wagerCount;
};
const selectPagingSortKeys = (state, args) => selectReducePaginationKeys(state, args);
const selectWagerCount = (state, args) => selectReduceWagerCount(state, args);
const selectPlayerForecast = (state, _ref3) => {
  let {
    productId,
    drawNumber,
    wagerId,
    resultIdx
  } = _ref3;
  if (!resultIdx || !drawNumber || !wagerId || !productId) {
    return null;
  }
  const data = tipsenApi.endpoints.getPlayerForecast.select({
    productId,
    drawNumber,
    wagerId,
    resultIdx
  })(state);
  return data.data;
};
setGlobal('svs.components.tipsen.querySelectors', {
  selectPagingSortKeys,
  selectWagerCount,
  selectPlayerForecast
});

 })(window);